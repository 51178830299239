// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.FN_intro]: "translation_missing",
  //  [keys.FN_createAndSaveFunnel]: "translation_missing",
  //  [keys.FN_organizeSource]: "translation_missing",
  //  [keys.FN_optimizeCampaigns]: "translation_missing",
  //  [keys.FN_introFn]: "translation_missing",
  //  [keys.FN_introBodyFirst]: "translation_missing",
  //  [keys.FN_introBodySecond]: "translation_missing",
  //  [keys.FN_introCTA]: "translation_missing",
  [keys.FN_creationNotAvailable]:
    "La creación de funnels aún no está disponible",
  // [keys.FN_orderingOptionChanged]: "translation_missing",
  // [keys.FN_updated]: "translation_missing",
  // [keys.FN_created]: "translation_missing",
  // [keys.FN_renamed]: "translation_missing",
  // [keys.FN_createFirstFunnel]: "translation_missing",
};

export default translate;
